import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import userHelper from "../infra/userHelper";

import {
  // AreaChartOutlined,
  // ControlOutlined,
  FolderOpenOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
const { Sider } = Layout;

export default function SideNavbar({ collapsed, navigate, user, setUser }) {
  const hasRole = useCallback(
    (roleName) => {
      return userHelper.hasRole(user, roleName);
    },
    [user]
  );
  const location = useLocation();

  const activeKey = useMemo(() => {
    const pathname = location.pathname;
    let key = "dashboard";
    console.log("pathname", pathname);
    if (pathname.includes("/projects")) {
      key = "projects";
    }
    if (pathname.includes("/reports")) {
      key = "reports";
    }
    if (pathname.includes("/settings")) {
      key = "settings";
    }
    console.log("activeKey", key);
    return key;
  }, [location]);

  const items = useMemo(() => {
    const baseItems = [
      // {
      //   key: "dashboard",
      //   icon: <AreaChartOutlined />,
      //   label: <Link to={"/dashboard"}>{t(TRANSLATIONS.DASHBOARD.key)}</Link>,
      // },
      {
        key: "projects",
        icon: <FolderOpenOutlined />,
        label: <Link to={"/projects"}>{t(TRANSLATIONS.PROJECT_LIST.key)}</Link>,
      },
      {
        key: "reports",
        icon: <FormOutlined />,
        label: <Link to={"/reports"}>{t(TRANSLATIONS.REPORTS.key)}</Link>,
      },
    ];

    const adminItems = [
      // {
      //   key: "settings",
      //   icon: <ControlOutlined />,
      //   label: <Link to={"/settings"}>{t(TRANSLATIONS.SETTINGS.key)}</Link>,
      // },
    ];

    let finalItems = [].concat(baseItems);
    if (hasRole("SUPERUSER")) {
      finalItems = finalItems.concat(adminItems);
    }
    return finalItems;
  }, [hasRole]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Menu
        theme="dark"
        mode="inline"
        activeKey={activeKey}
        defaultSelectedKeys={[activeKey]}
        items={items}
      />
    </Sider>
  );
}

import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import ProjectsTableSection from "../components/projects/ProjectsTableSection";
import { Button, Flex } from "antd";
import { Link } from "react-router-dom";

export default function Dashboard({ user, setUser }) {
  return (
    <div>
      <Flex align={"center"} justify={"flex-end"} gap={"middle"}>
        <Link to="/projects/create">
          <Button size="large" shape="round" type="primary">
            {t(TRANSLATIONS.ADD_PROJECT.key)}
          </Button>
        </Link>
      </Flex>
      <h1>{t(TRANSLATIONS.PROJECT_LIST.key)}</h1>
      <ProjectsTableSection user={user} path={"/projects"} />
    </div>
  );
}

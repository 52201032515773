import React, { useState, useEffect } from "react";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Layout, Button, Space, Select, Dropdown } from "antd";
import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import api from "../infra/api";
const { Header } = Layout;

const PopoverButton = React.lazy(() => import("./PopoverButton"));

export default function TopNavbar({
  navigate,
  user,
  setUser,
  collapsed,
  toggleCollapsed,
}) {
  const [userMenuActive, setUserMenuActive] = useState(false);
  // const [messagesActive, setMessagesActive] = useState(false);
  // const [messages, setMessages] = useState([]);
  // const messageCount = useMemo(() => {
  //   return messages.length;
  // }, [messages]);
  const [language, setLanguage] = useState("PL");
  const languages = [
    {
      value: "PL",
      label: "Polski",
    },
    {
      value: "EN",
      label: "English",
    },
  ];
  const [favProjects, setFavProjects] = useState([
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
  ]);

  useEffect(() => {
    console.log("TopNavbar mounted ...");
    console.log("fetch fav projects ...");
    // fetch fav projects
    api.projects.favouriteProjects().then((response) => {
      console.log("fav projects response", response);
      setFavProjects(
        response.data.results.map((project) => {
          return {
            key: project.id,
            label: (
              <a style={{ color: "#1677ff" }} href={"/projects/" + project.id}>
                {project.name}
              </a>
            ),
          };
        })
      );
    });
  }, []);

  const logout = () => {
    console.log("logout ...");
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    setUser(null);
    navigate("/login");
  };

  useEffect(() => {
    console.log("TopNavbar mounted ...");
    setLanguage(localStorage.getItem("lang") ?? language);
  }, [language]);

  return (
    <Header style={{ padding: 0, margin: 0, lineHeight: 0 }} theme="dark">
      <Space direction="horizontal" style={{ position: "absolute", left: 5 }}>
        <Space size="middle">
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              )
            }
            onClick={() => {
              console.log("toggle colapse");
              toggleCollapsed();
            }}
            style={{ width: 64, height: 64 }}
          />

          {/* <Image
            style={{ width: 64, height: 64, padding: 5 }}
            src="/logo192.png"
            preview={false}
          /> */}
        </Space>
      </Space>

      <Space direction="horizontal" style={{ position: "absolute", left: 300 }}>
        <Space size="middle" style={{ marginTop: 12 }}>
          <Dropdown
            menu={{
              items: favProjects,
            }}
            placement="bottom"
            arrow
          >
            <Button style={{ width: 300 }} size="large">
              {t(TRANSLATIONS.FAVOURITE_PROJECTS.key)}
            </Button>
          </Dropdown>
        </Space>
      </Space>

      <Space direction="horizontal" style={{ position: "absolute", right: 50 }}>
        <Space size="middle">
          <Select
            defaultValue={language}
            value={language}
            size="large"
            options={languages}
            onChange={(option) => {
              localStorage.setItem("lang", option);
              setLanguage(option);
              navigate(0);
            }}
            style={{ minWidth: 160 }}
          />

          <PopoverButton
            title={user.first_name.concat(" ", user.last_name)}
            subtitle={user.username.concat(" (", user.email, ")")}
            // content={
            //   <div>
            //     <Button
            //       type="link"
            //       size="large"
            //       disabled={true}
            //       onClick={() => {
            //         console.log("navigate to user profile");
            //       }}
            //     >
            //       {t(TRANSLATIONS.USER_PROFILE.key)}
            //     </Button>
            //   </div>
            // }
            onOpenChange={() => {
              setUserMenuActive(!userMenuActive);
            }}
            popoverButton={{
              icon: (
                <UserOutlined
                  className={
                    userMenuActive
                      ? "icon-user-profile-active"
                      : "icon-user-profile"
                  }
                  style={{ fontSize: "24px" }}
                />
              ),
            }}
            actions={{
              showButton: true,
              buttonType: "default",
              isBlockButton: true,
              isDangerButton: true,
              buttonSize: "large",
              buttonIcon: <PoweroffOutlined />,
              onClick: () => logout(),
              buttonText: t(TRANSLATIONS.LOGOUT.key),
            }}
          />
        </Space>
      </Space>
    </Header>
  );
}

import React from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";

// turns query string back into an object
// TODO: tu pewnie bedzie jakis refaktor bo to ze stacka ;)
function queryStringToObject(queryString = "", options = {}) {
  let queryObject = {};
  queryString &&
    decodeURIComponent(queryString.replace("?", ""))
      .split("&")
      .forEach((itemString) => {
        let [itemKey, itemValue] = itemString.split("=");
        if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
          queryObject[itemKey] = [];
        }
        Array.isArray(options[itemKey])
          ? queryObject[itemKey].push(itemValue)
          : (queryObject[itemKey] =
              typeof options[itemKey] === "number"
                ? parseInt(itemValue)
                : itemValue);
      });
  return queryObject;
}

function useQueryParams() {
  const { search } = useLocation();
  const navigate = useNavigate();

  // get query params
  const queryParams = React.useMemo(
    () => queryStringToObject(search),
    [search]
  );

  // updates the query params
  function setQueryParams(pathname, queryObj) {
    navigate({
      pathname: pathname,
      search:`?${createSearchParams(queryObj)}`,
    });
  }

  return { queryParams, setQueryParams };
}

export default useQueryParams;

import React, { PureComponent, useEffect, useState } from "react";

import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
  //   ResponsiveContainer,
} from "recharts";
import api from "../../infra/api";

export default function ReportsChart({ project }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("fetch stats");
    api.projects
      .stats(project.id)
      .then((response) => {
        console.log("stats response", response);
        setData(response.data);
      })
      .catch((error) => {
        console.error("fetch stats error", error);
      });
  }, [project]);
  return (
    // <ResponsiveContainer width="100%" height="100%">
    <LineChart
      width={500}
      height={200}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="passed"
        stroke="#31a852"
        name={t(TRANSLATIONS.PASSED.key)}
        // activeDot={{ r: 8 }}
      />
      <Line
        type="monotone"
        dataKey="failed"
        name={t(TRANSLATIONS.FAILED.key)}
        stroke="#a64226"
      />
      <Line
        type="monotone"
        dataKey="skipped"
        name={t(TRANSLATIONS.SKIPPED.key)}
        stroke="#979899"
      />
    </LineChart>
    // <AreaChart
    //   width={400}
    //   height={200}
    //   data={data}
    //   margin={{
    //     top: 10,
    //     right: 30,
    //     left: 0,
    //     bottom: 0,
    //   }}
    // >
    //   <CartesianGrid strokeDasharray="3 3" />
    //   <XAxis dataKey="date" />
    //   <YAxis />
    //   <Tooltip />
    //   <Area
    //     type="monotone"
    //     dataKey="skipped"
    //     stackId="1"
    //     stroke="#979899"
    //     fill="#979899"
    //   />
    //   <Area
    //     type="monotone"
    //     dataKey="failed"
    //     stackId="1"
    //     stroke="#a64226"
    //     fill="#a64226"
    //   />

    //   <Area
    //     type="monotone"
    //     dataKey="passed"
    //     stackId="0"
    //     stroke="#31a852"
    //     fill="#31a852"
    //   />
    // </AreaChart>
    // </ResponsiveContainer>
  );
}

import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Button, Col, Flex, Input, Row, Select, Form, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import UseForm from "../hooks/useForm";
import { useState } from "react";
import api from "../infra/api";

export default function Dashboard({ user, setUser }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { form, formHelpers, formFields } = UseForm({
    NAME: "name",
    DESCRIPTION: "description",
    PRIVATE: "private",
    TAGS: "tags",
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const boxStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    // border: "3px solid #ffafcc",
    marginBottom: 10,
    padding: 10,
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <h1>{t(TRANSLATIONS.ADD_PROJECT.key)}</h1>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={(values) => {
              console.log("onFinish", values);
              setLoading(true);
              api.projects
                .createProject(values)
                .then((response) => {
                  setTimeout(() => {
                    console.log("create project response", response);
                    setLoading(false);
                    navigate("/projects/" + response.data.id);
                  }, 500);
                })
                .catch((error) => {
                  setLoading(false);
                  if (error?.response.status === 400) {
                    formHelpers.setApiErrors(error.response.data);
                    // showNotification(
                    //   "error",
                    //   "Niepoprawne dane",
                    //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                    // );
                  } else if (error?.response.status >= 500) {
                    // showNotification(
                    //   "error",
                    //   "Błąd serwera",
                    //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                    // );
                    navigate("/500");
                  } else if (error?.response.status === 403) {
                    // showNotification(
                    //   "error",
                    //   "Brak uprawnień",
                    //   `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
                    // );
                    navigate("/403");
                  }
                });
            }}
          >
            <Form.Item
              label={t(TRANSLATIONS.PRIVATE.key)}
              name={formFields.PRIVATE}
              id={formFields.PRIVATE}
              initialValue={false}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={t(TRANSLATIONS.PROJECT_NAME.key)}
              name={formFields.NAME}
              id={formFields.NAME}
              rules={[
                {
                  validator: (_, value) => {
                    formHelpers.resetFieldGuiError(formFields.NAME);
                    if (value) {
                      return Promise.resolve();
                    } else {
                      formHelpers.setFieldGuiError(
                        formFields.NAME,
                        t(TRANSLATIONS.FIELD_REQUIRED.key)
                      );
                      return Promise.reject();
                    }
                  },
                },
              ]}
              validateStatus={
                formHelpers.errorFields.includes(formFields.NAME)
                  ? "error"
                  : null
              }
              help={
                formHelpers.errorFields.includes(formFields.NAME)
                  ? formHelpers.getFieldErrors(formFields.NAME).join(", ")
                  : null
              }
              normalize={(value) => value.trimStart()}
            >
              <Input maxLength={80} disabled={loading} showCount />
            </Form.Item>

            <Form.Item
              label={t(TRANSLATIONS.DESCRIPTION.key)}
              name={formFields.DESCRIPTION}
              id={formFields.DESCRIPTION}
              rules={[
                {
                  validator: (_, value) => {
                    formHelpers.resetFieldGuiError(formFields.DESCRIPTION);
                    if (value) {
                      return Promise.resolve();
                    } else {
                      formHelpers.setFieldGuiError(
                        formFields.DESCRIPTION,
                        t(TRANSLATIONS.FIELD_REQUIRED.key)
                      );
                      return Promise.reject();
                    }
                  },
                },
              ]}
              validateStatus={
                formHelpers.errorFields.includes(formFields.DESCRIPTION)
                  ? "error"
                  : null
              }
              help={
                formHelpers.errorFields.includes(formFields.DESCRIPTION)
                  ? formHelpers
                      .getFieldErrors(formFields.DESCRIPTION)
                      .join(", ")
                  : null
              }
              normalize={(value) => value.trimStart()}
            >
              <Input.TextArea
                showCount
                rows={4}
                maxLength={500}
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label={t(TRANSLATIONS.TAGS.key)}
              name={formFields.TAGS}
              id={formFields.TAGS}
            >
              <Select
                mode="tags"
                placeholder={t(TRANSLATIONS.TAGS.key)}
                disabled={loading}
              />
            </Form.Item>

            <Flex
              style={boxStyle}
              align={"center"}
              justify={"flex-end"}
              gap={"large"}
            >
              <Link to="/projects">
                <Button type="dark">{t(TRANSLATIONS.CANCEL.key)}</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t(TRANSLATIONS.CREATE.key)}
              </Button>
            </Flex>
          </Form>
        </Col>
      </Row>
      {/* <h1>{t(TRANSLATIONS.PROJECT_LIST.key)}</h1>
      <ProjectsTableSection user={user} path={"/projects"} /> */}
    </div>
  );
}

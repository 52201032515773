const userHelper = {
  hasRole: (user, roleName) =>
    user.permissions.user_roles
      .map(g => g.toLowerCase())
      .indexOf(roleName.toLowerCase()) !== -1,
  hasPermission: (user, permissionKey) =>
    user.permissions.user_permissions
      .map(p => p.toLowerCase())
      .indexOf(permissionKey.toLowerCase()) !== -1
};
export default userHelper;

import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Flex,
  Form,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import api from "../../infra/api";
import UseForm from "../../hooks/useForm";
import useQueryParams from "../../hooks/useQueryParams.hook";
import { useCollapse } from "react-collapsed";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

const ReportsTableSection = ({ user, path }) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { form: filterForm, formFields: filterFormFields } = UseForm({
    // PROJECT_NAME_CONTAINS: "project_name_contains",
    PROJECT: "project",
    TAGS_MATCH_ALL: "tags_match_all",
    TAGS_MATCH_ANY: "tags_match_any",
    STATUS: "status",
    FROM_TO: "from_to",
  });
  const [reportTags, setReportTags] = useState([]);
  const reportStatuses = [
    {
      value: "FAILED",
      label: t(TRANSLATIONS.FAILED.key),
    },
    {
      value: "PASSED",
      label: t(TRANSLATIONS.PASSED.key),
    },
    {
      value: "",
      label: t(TRANSLATIONS.ALL.key),
    },
  ];

  useEffect(() => {
    console.log("ReportsTableSection updated...");
    console.log("queryParams", queryParams);
    setPagination({
      pageSize: queryParams.pageSize ?? 10,
      current: queryParams.page ?? 1,
      total: 0,
    });
    setOrderBy(queryParams.orderBy ?? "-id");
    const filters = {};
    const project = queryParams.project ?? "";
    if (project) {
      filterForm.setFieldValue(filterFormFields.PROJECT, parseInt(project));
    }
    filters.project = project;
    const tags_match_all = queryParams.tags_match_all ?? "";
    if (tags_match_all) {
      filterForm.setFieldValue(
        filterFormFields.TAGS_MATCH_ALL,
        tags_match_all.split(",")
      );
    }
    filters.tags_match_all = tags_match_all;
    const tags_match_any = queryParams.tags_match_any ?? "";
    if (tags_match_any) {
      filterForm.setFieldValue(
        filterFormFields.TAGS_MATCH_ANY,
        tags_match_any.split(",")
      );
    }
    filters.tags_match_any = tags_match_any;
    const status = queryParams.status ?? "";
    if (status) {
      filterForm.setFieldValue(filterFormFields.STATUS, status);
    }
    filters.status = status;
    const pageSize = parseInt(queryParams.pageSize ?? 10);

    const from_to = queryParams.from_to ?? "";
    if (from_to) {
      const [from, to] = from_to.split(",");
      console.log("!!!!!");
      console.log("from", from);
      console.log("to", to);
      console.log("!!!!!");
      filterForm.setFieldValue(filterFormFields.FROM_TO, [
        dayjs(from, "YYYY-MM-DD"),
        dayjs(to, "YYYY-MM-DD"),
      ]);
    }
    filters.from_to = from_to;

    fetchTags();
    fetchProjects();
    fetchReports({
      page: queryParams.page ?? 1,
      pageSize: pageSize,
      orderBy: queryParams.orderBy ?? "-id",
      filters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 1,
    current: 1,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState("-id");
  const [loading, setLoading] = useState(false);
  const fetchTags = () => {
    console.log("fetchTags...");
    api.reports.tags().then((res) => {
      console.log("tags response", res.data);
      setReportTags(
        res.data.map((tag) => ({
          value: tag,
          label: tag,
        }))
      );
    });
  };
  const fetchProjects = () => {
    console.log("fetchProjects...");
    api.projects.projects(1, 500, "-id").then((res) => {
      console.log("projects response", res.data);
      setProjects(res.data.results);
    });
  };
  const fetchReports = ({
    page = 1,
    pageSize = 10,
    orderBy = "-id",
    filters = {},
  }) => {
    console.log("fetchReports...");
    console.log(
      "page",
      page,
      "pageSize",
      pageSize,
      "orderBy",
      orderBy,
      "filters",
      filters
    );
    setLoading(true);
    api.reports
      .reports(page, pageSize, orderBy, filters)
      .then((res) => {
        console.log("reports response", res.data);
        setReports(res.data.results);
        setPagination((old) => {
          return {
            ...old,
            current: res.data.pagination.current,
            pageSize: pageSize,
            total: res.data.pagination.count,
          };
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  };

  const hiddenColumns = ["id"];
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      hidden: hiddenColumns.includes("id"),
    },
    {
      width: 120,
      title: t(TRANSLATIONS.CREATED_DATE.key),
      key: "created_date",
      render: (item) => {
        return new Date(item.created_date).toLocaleString();
      },
      sortDirections: ["ascend", "descend"],
      sorter: true,
      hidden: hiddenColumns.includes("created_date"),
      sortOrder:
        orderBy.replace("-", "") === "created_date"
          ? orderBy.startsWith("-")
            ? "descend"
            : "ascend"
          : null,
    },
    {
      // width: 200,
      title: t(TRANSLATIONS.PROJECT.key),
      key: "project__name",
      render: (item) => {
        return (
          <Link to={`/projects/${item.project.id}`}>{item.project.name}</Link>
        );
      },
      sortDirections: ["ascend", "descend"],
      sorter: true,
      hidden: hiddenColumns.includes("project__name"),
      sortOrder:
        orderBy.replace("-", "") === "project__name"
          ? orderBy.startsWith("-")
            ? "descend"
            : "ascend"
          : null,
    },

    {
      // width: 200,
      title: t(TRANSLATIONS.TAGS.key),
      render: (item) => {
        return (
          <div>
            {item.tags.map((tag) => (
              <Tag key={tag} color="blue">
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
      hidden: hiddenColumns.includes("tags"),
    },
    {
      width: 200,
      title: t(TRANSLATIONS.SUMMARY.key),
      render: (item) => {
        return (
          <div>
            <Tag color="blue">total: {item.data.summary?.total}</Tag>
            <Tag color="red">failed: {item.data.summary?.failed}</Tag>
            <Tag color="green">passed: {item.data.summary?.passed}</Tag>
            <Tag color="grey">skipped: {item.data.summary?.skipped}</Tag>
          </div>
        );
      },
      hidden: hiddenColumns.includes("summary"),
    },
    {
      width: 50,
      title: t(TRANSLATIONS.STATUS.key),
      render: (item) => {
        const icon =
          item.status === "PASSED" ? (
            <CheckCircleOutlined style={{ fontSize: 30, color: "green" }} />
          ) : (
            <ExclamationCircleOutlined style={{ fontSize: 30, color: "red" }} />
          );
        return icon;
      },
      hidden: hiddenColumns.includes("status"),
      key: "status",
      sorter: true,
      sortOrder:
        orderBy.replace("-", "") === "status"
          ? orderBy.startsWith("-")
            ? "descend"
            : "ascend"
          : null,
    },
    {
      width: 100,
      title: t(TRANSLATIONS.ACTIONS.key),
      render: (item) => {
        const items = [
          {
            key: "OpenCIJob",
            label: t(TRANSLATIONS.OPEN_CI_JOB.key),
            url: item.ci_job_url,
            icon: <RocketOutlined />,
          },
        ];
        const handleMenuClick = ({ key }) => {
          console.log("handleMenuClick", key);
          if (key === "OpenCIJob") {
            window.open(item.ci_job_url, "_blank");
          } else {
            console.error("Unknown key", key);
          }
        };
        const menuProps = {
          items,
          onClick: handleMenuClick,
        };
        return (
          <Dropdown.Button menu={menuProps}>
            <Link to={`/reports/${item.id}`}>
              {t(TRANSLATIONS.OPEN_REPORT.key)}
            </Link>
          </Dropdown.Button>
        );
      },
      hidden: hiddenColumns.includes("actions"),
    },
  ];
  const boxStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    // border: "3px solid #ffafcc",
    marginBottom: 10,
    padding: 10,
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  // TODO: animate ???
  // https://blog.logrocket.com/create-collapsible-react-components-react-collapsed/
  function CollapsibleFilters() {
    const [isExpanded, setExpanded] = useState(
      window.localStorage.getItem("reports_filters_expanded") === "1"
    );
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
    function handleOnClick() {
      const newState = !isExpanded;
      if (newState) {
        window.localStorage.setItem("reports_filters_expanded", "1");
      } else {
        window.localStorage.setItem("reports_filters_expanded", "0");
      }
      setExpanded(!isExpanded);
    }
    return (
      <div className="collapsible">
        <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
          {isExpanded ? (
            <Button type="dark" shape="round">
              {t(TRANSLATIONS.HIDE_FILTERS.key)}
            </Button>
          ) : (
            <Button type="primary" shape="round">
              {t(TRANSLATIONS.SHOW_FILTERS.key)}
            </Button>
          )}
        </div>
        <div {...getCollapseProps()}>
          <div className="content">
            <Form
              {...formItemLayout}
              form={filterForm}
              onFinish={(values) => {
                console.log("onFinish", values);
                const filters = {};
                // filters.project_name_contains =
                //   values[filterFormFields.PROJECT_NAME_CONTAINS] ?? "";
                filters.project = values[filterFormFields.PROJECT] ?? "";
                console.log(
                  "tags_match_all",
                  values[filterFormFields.TAGS_MATCH_ALL]
                );
                filters.tags_match_all =
                  values[filterFormFields.TAGS_MATCH_ALL]?.join(",") ?? "";

                console.log(
                  "tags_match_any",
                  values[filterFormFields.TAGS_MATCH_ANY]
                );
                filters.tags_match_any =
                  values[filterFormFields.TAGS_MATCH_ANY]?.join(",") ?? "";

                filters.status = values[filterFormFields.STATUS] ?? "";
                const fromTo = values[filterFormFields.FROM_TO] ?? [];
                console.log("fromTo", fromTo, typeof fromTo);
                function formatDate(date) {
                  let datePart = [
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate(),
                  ]
                    .map((n, i) => n.toString().padStart(2, "0"))
                    .join("-");
                  let timePart = [
                    date.getHours(),
                    date.getMinutes(),
                    date.getSeconds(),
                  ]
                    .map((n, i) => n.toString().padStart(2, "0"))
                    .join(":");
                  return datePart + "T" + timePart + ".000Z";
                }
                console.log("FROMMMM", formatDate(new Date(fromTo.at(0))));
                console.log("TOO", formatDate(new Date(fromTo.at(1))));
                if (fromTo.length === 2) {
                  const realfromto = [
                    formatDate(new Date(fromTo.at(0))),
                    formatDate(new Date(fromTo.at(1))),
                  ];
                  // T22:00:00.000Z
                  filters.from_to = `${
                    realfromto.at(0).slice(0, 10) + "T00:00:00.000Z"
                  },${realfromto.at(1).slice(0, 10) + "T23:59:59.999Z"}`;
                }
                console.log("filters", filters);
                setQueryParams(path, {
                  ...queryParams,
                  ...filters,
                  page: 1,
                });
              }}
            >
              <Form.Item
                label={t(TRANSLATIONS.FROM_TO.key)}
                name={filterFormFields.FROM_TO}
                id={filterFormFields.FROM_TO}
              >
                <RangePicker allowClear />
              </Form.Item>
              <Form.Item
                label={t(TRANSLATIONS.STATUS.key)}
                name={filterFormFields.STATUS}
                id={filterFormFields.STATUS}
              >
                <Select
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t(TRANSLATIONS.SELECT_STATUS.key)}
                  options={reportStatuses}
                />
              </Form.Item>
              <Form.Item
                label={t(TRANSLATIONS.PROJECT.key)}
                name={filterFormFields.PROJECT}
                id={filterFormFields.PROJECT}
              >
                <Select
                  allowClear
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder={t(TRANSLATIONS.SELECT_PROJECT.key)}
                  options={projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATIONS.TAGS_MATCH_ALL.key)}
                name={filterFormFields.TAGS_MATCH_ALL}
                id={filterFormFields.TAGS_MATCH_ALL}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  defaultValue={[]}
                  options={reportTags}
                />
              </Form.Item>

              <Form.Item
                label={t(TRANSLATIONS.TAGS_MATCH_ANY.key)}
                name={filterFormFields.TAGS_MATCH_ANY}
                id={filterFormFields.TAGS_MATCH_ANY}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  defaultValue={[]}
                  options={reportTags}
                />
              </Form.Item>

              <Flex
                style={boxStyle}
                align={"center"}
                justify={"flex-end"}
                gap={"large"}
              >
                <Button
                  type="dark"
                  itemType="button"
                  onClick={() => {
                    setQueryParams(path, {
                      ...queryParams,
                      project_name_contains: "",
                      tags_match_all: "",
                      tags_match_any: "",
                    });
                    filterForm.resetFields();
                  }}
                >
                  {t(TRANSLATIONS.CLEAR.key)}
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t(TRANSLATIONS.SEARCH.key)}
                </Button>
              </Flex>
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Row>
      <Col span={24}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12} offset={0}>
            <CollapsibleFilters />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <Flex align={"center"} justify={"flex-end"} gap={"middle"}>
              {pagination.total > 0 && (
                <Pagination
                  onChange={(page, pageSize) => {
                    console.log("onChange pagination", page, pageSize);
                    setQueryParams(path, {
                      ...queryParams,
                      page: page,
                      pageSize: pageSize,
                    });
                  }}
                  current={pagination.current}
                  defaultPageSize={pagination.pageSize}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  showTotal={(total) =>
                    `${t(TRANSLATIONS.TOTAL.key)} ${total} ${t(
                      TRANSLATIONS.ITEMS.key
                    )}`
                  }
                  showSizeChanger={false}
                  showLessItems={true}
                  itemRender={(current, type, originalElement) => {
                    if (type === "prev") {
                      return (
                        <Button>
                          <LeftOutlined />
                        </Button>
                      );
                    }
                    if (type === "next") {
                      return (
                        <Button>
                          <RightOutlined />
                        </Button>
                      );
                    }
                    return originalElement;
                  }}
                />
              )}
              <Select
                style={{ width: 150 }}
                defaultValue={pagination.pageSize}
                options={[1, 10, 25, 50].map((value) => ({
                  value: value,
                  label: (
                    <span>
                      {value} {t(TRANSLATIONS.PER_PAGE.key)}
                    </span>
                  ),
                }))}
                onChange={(value) => {
                  console.log("onChange select per page", value);
                  setQueryParams(path, {
                    ...queryParams,
                    page: 1,
                    pageSize: value,
                  });
                }}
                value={pagination.pageSize}
              />
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              id="agent-teams"
              pagination={false}
              size="small"
              columns={columns}
              dataSource={reports ?? []}
              loading={loading}
              showSorterTooltip={true}
              locale={{
                triggerAsc: `${t(TRANSLATIONS.SORTING_ASCENDING_LABEL.key)}`,
                triggerDesc: `${t(TRANSLATIONS.SORTING_DESCENDING_LABEL.key)}`,
                cancelSort: `${t(TRANSLATIONS.SORTING_CANCEL_LABEL.key)}`,
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`${t(TRANSLATIONS.EMPTY_LIST_TITLE.key)}`}
                  />
                ),
              }}
              rowKey="id"
              onChange={(pagination, filters, sorter) => {
                console.log("only sorter", sorter);
                const sortedOrder = sorter?.order ?? "";
                const sortedField = sorter?.columnKey ?? "";
                const orderBy =
                  sortedOrder === "ascend"
                    ? `${sortedField}`
                    : sortedOrder === "descend"
                    ? `-${sortedField}`
                    : "-id";
                console.log("orderBy", orderBy);
                setOrderBy(orderBy);
                setQueryParams(path, {
                  ...queryParams,
                  orderBy: orderBy,
                });
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ReportsTableSection;

// import t from "../translate";
// import { TRANSLATIONS } from "../translate/translations";
// import ProjectsTableSection from "../components/projects/ProjectsTableSection";

export default function Dashboard({ user, setUser }) {
  return (
    <div>
      <h1>Dashboard</h1>
      {/* TODO: first translate */}
      {/* <h2>{t(TRANSLATIONS.PROJECT_LIST.key)}</h2>
      <ProjectsTableSection user={user} /> */}
    </div>
  );
}

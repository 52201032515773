import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

// TODO: przerobić tak aby wpadała cofało usera do poprzedniej strony
// ^^ można użyć useHistory().goBack() - co w przypadku, gdy user wkleił link w przeglądarce ?
// https://ant.design/components/result
export default function Page404() {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="Sorry, the page you want to visit does not exist."
      extra={
        <div>
          {/* <Button
            type="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go back
          </Button> */}
          <Button
            type="link"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back to Dashboard
          </Button>
        </div>
      }
    />
  );
}

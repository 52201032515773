import "./App.css";
import "./styles/Header.css";
import "./styles/Buttons.css";
import "./styles/Modals.css";

import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";

import api from "./infra/api";
import useBus from "use-bus";

import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectCreatePage from "./pages/ProjectCreatePage";
import ProjectEditPage from "./pages/ProjectEditPage";
import ReportsPage from "./pages/ReportsPage";
import ReportDetailsPage from "./pages/ReportDetailsPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";

import Page500 from "./pages/errors/Page500";
import Page404 from "./pages/errors/Page404";
import Page403 from "./pages/errors/Page403";

import TopNavbar from "./components/TopNavbar";
import SideNavbar from "./components/SideNavbar";

import { Layout, theme, notification } from "antd";
const { Content } = Layout;

function App() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("bar-collapsed", collapsed ? "NO" : "YES");
  };
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const cleanStorage = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("user");
  };

  useEffect(() => {
    const loadUserData = async () => {
      console.log("check for user and tokens in local storage");
      try {
        console.log("fetch tokens from local storage");
        const storedTokens = JSON.parse(localStorage.getItem("tokens"));
        const refresh = storedTokens.refresh;
        const refreshTokenResponse = await api.auth.refreshToken();
        const access = refreshTokenResponse.data.access;
        const tokens = JSON.stringify({
          access,
          refresh,
        });
        localStorage.setItem("tokens", tokens);
      } catch (error) {
        console.warn("must relogin:");
        cleanStorage();
        setUser(null);
        navigate("/login");
      }

      try {
        const storedTokens = JSON.parse(localStorage.getItem("tokens"));
        const access = storedTokens.access;
        const userResponse = await api.users.getMe(access);
        const user = userResponse.data;
        localStorage.setItem("user", JSON.stringify({ user }));
        setUser(user);
      } catch (error) {
        console.error("refresh user error ...", error);
        setUser(null);
        cleanStorage();
        navigate("/login");
      }
    };
    console.log("App mounted ...");
    loadUserData();

    console.log("check for bar state in local storage");
    const collapsed = localStorage.getItem("bar-collapsed") ?? "NO";
    console.log("collapsed", collapsed);
    if (collapsed === "YES") {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    // TODO: when navigate is in array each view call /users/me
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBus("SHOW_NOTIFICATION", (event) => {
    openNotification(
      event.payload.notification.type,
      event.payload.notification.title,
      event.payload.notification.description
    );
  });

  const openNotification = (type, title, description) => {
    notificationApi[type]({
      message: title,
      description: description,
      closeIcon: false,
      duration: 5,
    });
  };

  return (
    <div>
      {!user && (
        <Routes>
          <Route path="/login" element={<LoginPage setUser={setUser} />} />
        </Routes>
      )}

      {user && (
        <Layout style={{ minHeight: "100vh" }}>
          {contextHolder}

          <TopNavbar
            toggleCollapsed={toggleCollapsed}
            navigate={navigate}
            user={user}
            setUser={setUser}
            collapsed={collapsed}
          />

          <Layout>
            <SideNavbar
              collapsed={collapsed}
              navigate={navigate}
              user={user}
              setUser={setUser}
            />

            <Suspense fallback={<div>Loading...</div>}>
              <Content
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Routes>
                  <Route
                    index
                    path="/dashboard"
                    element={<DashboardPage user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/projects"
                    element={<ProjectsPage user={user} setUser={setUser} />}
                  />

                  <Route
                    index
                    path="/projects/create"
                    element={
                      <ProjectCreatePage user={user} setUser={setUser} />
                    }
                  />

                  <Route
                    index
                    path="/projects/:id"
                    element={
                      <ProjectDetailsPage user={user} setUser={setUser} />
                    }
                  />
                  <Route
                    index
                    path="/projects/:id/edit"
                    element={<ProjectEditPage user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/reports"
                    element={<ReportsPage user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/reports/:id"
                    element={
                      <ReportDetailsPage user={user} setUser={setUser} />
                    }
                  />
                  <Route
                    index
                    path="/"
                    element={
                      <Navigate to="/dashboard" user={user} setUser={setUser} />
                    }
                  />
                  <Route
                    index
                    path="/404"
                    element={<Page404 user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/403"
                    element={<Page403 user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/500"
                    element={<Page500 user={user} setUser={setUser} />}
                  />
                  <Route
                    index
                    path="/*"
                    element={<Page404 user={user} setUser={setUser} />}
                  />
                </Routes>
              </Content>
            </Suspense>
          </Layout>
        </Layout>
      )}
    </div>
  );
}

export default App;

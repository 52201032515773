import { Button, Result } from "antd";

// TODO: przerobić tak aby wpadała cofało usera do poprzedniej strony
// ^^ można użyć useHistory().goBack() - co w przypadku, gdy user wkleił link w przeglądarce ?
// https://ant.design/components/result
export default function Page403({ navigate }) {
  return (
    <Result
      status="403"
      title="Sorry, you are not authorized to view this page."
      extra={
        <Button
          type="link"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back to Dashboard
        </Button>
      }
    />
  );
}

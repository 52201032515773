import { useEffect, useState } from "react";
import api from "../../infra/api";
import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import TokenCreate from "./TokenCreate";
import { Button, Card, Tooltip, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
export default function TokensSection({ project }) {
  const [tokens, setTokens] = useState([]);
  const fetchTokens = () => {
    api.projects.tokens(project.id).then((response) => {
      console.log("fetch tokens response", response);
      setTokens(response.data.results);
    });
  };
  useEffect(() => {
    fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteToken = (tokenId) => {
    if (window.confirm(t(TRANSLATIONS.DELETE_TOKEN_CONFIRMATION_MESSAGE.key))) {
      console.log("delete token", tokenId);
      api.projects.deleteToken(project.id, tokenId).then((response) => {
        console.log("delete token response", response);
        fetchTokens();
      });
    }
  };

  return (
    <Card
      title={t(TRANSLATIONS.TOKENS.key)}
      bordered={true}
      style={{ marginTop: "20px" }}
    >
      <TokenCreate project={project} tokenCreated={() => fetchTokens()} />
      <p style={{ marginBottom: 15 }}></p>
      {/* TODO: sees tokens only when user is project admin */}
      {tokens.map((token) => (
        <Card style={{ marginBottom: 10 }} key={token.id}>
          <Text copyable code>
            {token.token}
          </Text>
          <Tooltip title={t(TRANSLATIONS.DELETE.key)}>
            <Button type="danger">
              <DeleteOutlined
                style={{ color: "red" }}
                onClick={() => deleteToken(token.id)}
              />
            </Button>
          </Tooltip>
          <br />
          <Text style={{ fontSize: "10px" }}>
            {t(TRANSLATIONS.CREATED_DATE.key)}:{" "}
          </Text>
          <Text style={{ fontSize: "10px" }} italic>
            {new Date(token.created_date).toLocaleString()}
          </Text>
        </Card>
      ))}
    </Card>
  );
}

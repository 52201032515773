import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../infra/api";
import { Col, Row } from "antd";

export default function ReportDetailsPage({ user, setUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log("fetch reprot details");
    // fetch project details
    setLoading(true);
    api.reports
      .report(id)
      .then((response) => {
        console.log("report details response", response);
        setTimeout(() => {
          setLoading(false);
          setReport(response.data);
        }, 500);
      })
      .catch((error) => {
        console.error("fetch report details error", error);
        setLoading(false);
        if (error.response.status === 401) {
          setUser(null);
          // TODO: if 403
        } else if (error.response.status === 404) {
          // project not found
          navigate("/404");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row>
      <Col span={24}>
        {loading && <h1>{t(TRANSLATIONS.LOADING.key)}</h1>}
        {report && !loading && (
          <Row>
            <Col span={24}>
              <h1>
                {t(TRANSLATIONS.REPORT.key)} id: {id}
              </h1>
              <p>todo </p>
              <Link to={`/projects/${report.project}`}>{t(TRANSLATIONS.GO_TO_PROJECT.key)}</Link>
              <p>{JSON.stringify(report)}</p>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

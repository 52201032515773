import { Button } from "antd";
import t from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import { useState } from "react";
import api from "../../infra/api";

export default function TokenCreate({ project, tokenCreated }) {
  const [createMode, setCreateMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const createToken = () => {
    setLoading(true);
    console.log("create token");
    api.projects.createToken(project.id).then((response) => {
      console.log("create token response", response);
      setTimeout(() => {
        setLoading(false);
        setCreateMode(false);
        tokenCreated();
      }, 500);
    });
  };
  return (
    <div id="tokens-create-section">
      {!createMode && (
        <Button type="primary" shape="round" onClick={() => setCreateMode(true)}>
          {t(TRANSLATIONS.ADD_TOKEN.key)}
        </Button>
      )}
      {createMode && (
        <div>
          <h2>{t(TRANSLATIONS.ADD_TOKEN_CONFIRMATION_MESSAGE.key)}</h2>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              setCreateMode(false);
            }}
          >
            {t(TRANSLATIONS.CANCEL.key)}
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => createToken()}
          >
            {t(TRANSLATIONS.CONFIRM.key)}
          </Button>
        </div>
      )}
    </div>
  );
}

import t from "../translate";
import { TRANSLATIONS } from "../translate/translations";
import ReportsTableSection from "../components/reports/ReportsTableSection";

export default function Dashboard({ user, setUser }) {
  return (
    <div>
      <h1>{t(TRANSLATIONS.REPORTS_LIST.key)}</h1>
      <ReportsTableSection user={user} path={"/reports"} />
    </div>
  );
}
